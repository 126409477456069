<template>
  <div>
    <!-- <div class="pb-3">
      <div class="d-flex justify-content-between font-weight-bolder py-1">
        <div>Stock Request Progress</div>
        <div>{{ percentage }}%</div>
      </div>
      <b-progress
        :value="percentage"
        max="100"
      />
    </div> -->
    <div class="pl-4">
      <ul class="iq-timeline">
        <slot
          name="approvals"
          v-bind="{
            stage: stockRequest?.stage || 0,
            status: stockRequest?.status || 'Pending',
            created_at: stockRequest?.created_at || null,
            document_number: stockRequest.references?.document_number || null,
            transfer_number: stockRequest.references?.transfer_number || ' ',
            sap_doc_entry: stockRequest.references?.sap_doc_entry || null,
            purchase_number: stockRequest.references?.purchase_number || null,
            approvals: stockRequest?.approvals || []
          }"
        />
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TimelineBase',

  props: {
    stockRequest: {
      type: Object,
      default: () => ({
        stage: 0,
        status: null,
        created_at: null,
        references: {
          document_number: null,
          transfer_number: null,
          sap_doc_entry: null,
          purchase_number: null
        },
        approvals: []
      }),
      required: true
    }
  },

  computed: {
    percentage () {
      return Math.floor(
        (
          this.stockRequest.approvals.filter(
            approver => [
              'Approved',
              'Dispatched',
              'Scheduled',
              'Posted',
              'Received',
              'Closed'
            ].includes(approver.status)
          ).length / this.stockRequest.approvals.length
        ) * 100
      )
    }
  }
}
</script>

<template>
  <b-container
    class="mt-2"
    fluid
  >
    <!-- Filters -->
    <b-row>
      <b-col
        cols="12"
        class="px-1"
      >
        <b-form-group
          label="Search"
          label-for="filterPendingSearch"
          label-class="font-weight-bolder"
        >
          <b-input
            id="filterPendingSearch"
            v-model="tablePending.filter.search"
            placeholder="search here"
            autocomplete="off"
            debounce="1000"
            type="text"
          />
        </b-form-group>
      </b-col>
    </b-row>

    <!-- Table -->
    <b-row
      v-if="Number(tabIndex) === 0"
      class="mt-1"
    >
      <b-col
        cols="12"
        class="px-1"
      >
        <b-table
          ref="tablePending"
          small
          hover
          striped
          bordered
          responsive
          show-empty
          :items="tableProvider"
          :busy="tablePending.busy"
          :filter="tablePending.filter"
          :fields="tablePending.fields"
          :stacked="tablePending.stacked"
          :sort-by.sync="tablePending.sortBy"
          :sort-desc.sync="tablePending.sortDesc"
          :sort-direction="tablePending.sortDirection"
          :filter-included-fields="tablePending.filterOn"
          :current-page="tablePending.currentPage"
          :per-page="tablePending.perPage"
        >
          <template #cell(index)="{ index }">
            {{ tableRowNumber(tablePending, index) }}
          </template>

          <template #cell(action)="{ item }">
            <div class="text-md-nowrap d-flex flex-column flex-md-row justify-content-md-start justify-content-center">
              <b-button
                size="sm"
                variant="warning"
                class="mr-0 mr-md-1 mb-1 mb-md-0"
                :disabled="tablePending.busy"
                @click.prevent="show(item)"
              >
                View
              </b-button>
              <b-button
                v-if="Number(item.stage) <= 1"
                size="sm"
                variant="outline-danger"
                :disabled="tablePending.busy"
                @click.prevent="cancelRequest(item)"
              >
                Cancel
              </b-button>
            </div>
          </template>

          <template #cell(purchase_number)="{ value }">
            <b
              class="text-md-nowrap"
              v-text="value"
            />
          </template>

          <template #cell(transfer_number)="{ value }">
            <b
              class="text-md-nowrap"
              v-text="value"
            />
          </template>

          <template #cell(status)="{ value }">
            <b
              :class="[
                'min-w-80px',
                statusByVariant(value, 'text')
              ]"
              v-text="value"
            />
          </template>

          <template #cell(approvals)="{ item, value }">
            <div
              class="text-md-nowrap"
            >
              <div>
                {{ value.approver_label }}
              </div>
              <b-progress
                class="bg-info-light"
                :value="value.approver_label"
                :max="item.approvals.length"
                show-value
              >
                <b-progress-bar :value="value.count">
                  <span><strong>{{ value.count }} / {{ item.approvals.length }}</strong></span>
                </b-progress-bar>
              </b-progress>
            </div>
          </template>

          <template #cell()="{ value }">
            <div
              class="text-md-nowrap"
              v-text="value"
            />
          </template>

          <template #table-busy>
            <div class="text-center py-5">
              <b-icon
                icon="stopwatch"
                font-scale="5"
                animation="cylon"
              />
              <p class="h3 py-2">
                <strong>Loading . . .</strong>
              </p>
            </div>
          </template>

        </b-table>
      </b-col>

      <b-col
        cols="12"
        md="6"
        class="mt-1 mb-2 px-1"
      >
        <b-select
          v-model="tablePending.perPage"
          :options="tablePending.pageOptions"
          :disabled="tablePending.busy"
          class="w-100 w-md-25"
          size="sm"
        />
      </b-col>

      <b-col
        cols="12"
        md="6"
        class="d-flex justify-content-center justify-content-md-end mt-1 px-1"
      >
        <b-pagination
          v-model="tablePending.currentPage"
          :total-rows="tablePending.totalRows"
          :per-page="tablePending.perPage"
          :disabled="tablePending.busy"
          aria-controls="table"
          prev-text="Prev"
          next-text="Next"
          first-number
          last-number
          pills
        />
      </b-col>

    </b-row>
  </b-container>
</template>
<script>
import MISC from '@/mixins/misc'
import FORMATTER from '@/mixins/formatter'
import { SUserRequest } from '@/services'
// import QUERY from 'lodash'

export default {

  name: 'UserRequestPending',

  mixins: [
    MISC,
    FORMATTER
  ],

  props: {
    tabIndex: {
      type: Number,
      default: () => 0
    }
  },

  data () {
    return {
      tablePending: {
        busy: false,
        filter: {
          search: null
        },
        filterOn: [],
        stacked: this.isMobile(),
        sortBy: null,
        sortDesc: false,
        sortDirection: 'asc',
        pageOptions: [10, 25, 50, 100],
        currentPage: 1,
        totalRows: 0,
        perPage: 10,
        fields: [
          { mobile: 0, key: 'index', label: '#', class: 'text-center' },
          { mobile: 12, key: 'action', class: 'text-center' },
          { mobile: 1, key: 'created_at', label: 'Requested At', class: 'min-w-175px', formatter: this.dateTimeShortFormatter, sortable: true },
          { mobile: 2, key: 'status', label: 'Status', class: 'text-center min-w-80px' },
          { mobile: 3, key: 'purchase_number', label: 'PO Number', class: 'text-center min-w-150px', sortable: true },
          { mobile: 4, key: 'transfer_number', label: 'Transfer Number', class: 'text-center min-w-150px', sortable: true },
          { mobile: 5, key: 'origin_name', label: 'Requested To', class: 'min-w-200px' },
          { mobile: 6, key: 'group_name', label: 'Group', class: 'min-w-200px' },
          { mobile: 7, key: 'transfer_details.length', label: 'Products', class: 'text-center' },
          { mobile: 8, key: 'delivery_details.customer', label: 'Customer', class: 'min-w-200px' },
          { mobile: 9, key: 'customer_direct', label: 'D2C', class: 'text-center', formatter: this.yesOrNo },
          { mobile: 10, key: 'approvals', label: 'Current Approver', formatter: this.approvalProgress },
          { mobile: 11, key: 'updated_at', class: 'min-w-175px', formatter: this.dateTimeShortFormatter }
        ].sort(this.scaleOnMobile)
      }
    }
  },

  watch: {
    'stockRequests.selected' (newValues) {
      if (newValues.length === 0) {
        this.selected.indeterminate = false
        this.selected.allSelected = false
      } else if (newValues.length === this.$refs.tablePending?.localItems.length) {
        this.selected.indeterminate = false
        this.selected.allSelected = true
      } else {
        this.selected.indeterminate = true
        this.selected.allSelected = false
      }
    }
  },

  methods: {

    async tableProvider ({ currentPage, perPage, sortBy, sortDesc, filter }) {
      this.tablePending.busy = true
      return await SUserRequest.get({
        page: currentPage,
        per_page: perPage,
        sort: sortBy,
        sort_desc: sortDesc,
        filter_text: filter.search
      }).then(({ data }) => {
        this.tablePending.totalRows = data.total_rows
        return data.items
      }).catch(() => {
        this.tablePending.totalRows = 0
        return []
      }).finally(() => {
        this.tablePending.busy = false
      })
    },

    tableRefresh () {
      this.$refs.tablePending.refresh()
    },

    tableSelectRows (selectAll) {
      this.stockRequests.selected = selectAll ? this.$refs.tablePending.localItems.map(
        item => item.id
      ) : []
    },

    show (stockRequest) {
      this.$emit('show', stockRequest)
    },

    async create (payload) {
      return new Promise((resolve, reject) => {
        SUserRequest.post(payload).then(
          ({ data: { message } }) => {
            this.$bvModal.hide('modalRequest')
            this.swalSuccess(message).then(() => {
              this.tableRefresh()
            })
            resolve(message)
          }
        ).catch(
          ({ message }) => reject(message)
        )
      })
    },

    cancelRequest (stockRequest) {
      this.swalConfirmWithRemarks({
        remarksRequired: true,
        preConfirm: async remarks => await SUserRequest.put({
          id: stockRequest.id,
          remarks: remarks,
          status: 'Canceled'
        }).then(({ data: { message } }) => {
          this.swalSuccess(message).then(() => {
            this.tableRefresh()
          })
        }),
        html: '<h6>Mark this request as Canceled?</h6>',
        confirmButtonText: 'Mark as Canceled',
        cancelButtonText: 'Discard',
        customClass: {
          confirmButton: 'btn btn-sm btn-danger'
        }
      })
    }
  }
}
</script>

<template>
  <li>
    <div
      :style="(
        approver.stage <= 6 ? {
          borderLeft: '3px dashed #ccc',
          position: 'absolute',
          height: '70%',
          marginLeft: '-54px',
          bottom: '10px'
        } : {}
      )"
    />
    <div
      class="timeline-dots"
    >
      <i
        :class="['fad', `fa-circle-${icon}`, `text-${variant}`]"
        style="font-size: 32px;"
      />
    </div>
    <div
      class="timeline-label"
    >
      <div
        :class="[`text-${variant}`]"
        v-text="approver.status"
      />
    </div>
    <div
      class="mb-3 min-h-175px"
    >
      <div class="d-flex d-flex flex-column flex-md-row">
        <div class="flex-grow-1">
          <div
            :class="['font-weight-bolder', `text-${variant}`]"
            style="font-size: 18px; margin-top: 4px;"
            v-text="approver.approver_action"
          />
          <small
            class="text-dark"
            v-text="approver.approver_label"
          />
        </div>
        <div
          v-if="Number(approver.stage) === 0"
          class="font-weight-bolder d-flex flex-column align-items-end"
        >
          <strong
            class="mt-1"
            style="font-size: 18px"
            v-text="stockRequest.purchase_number"
          />
          <small>PO Number</small>
        </div>
        <div
          v-if="Number(approver.stage) === 2 && Number(stockRequest.stage) >= 2 && ['Approved'].includes(approver.status)"
          class="font-weight-bolder d-flex flex-md-row flex-column align-items-end"
          style="row-gap: 5px; column-gap: 25px;"
        >
          <div class="d-flex flex-column align-items-end">
            <strong
              class="mt-1"
              style="font-size: 18px"
              v-text="stockRequest.document_number"
            />
            <small>Document Number</small>
          </div>
          <div class="d-flex flex-column align-items-end">
            <strong
              class="mt-1"
              style="font-size: 18px"
              v-text="stockRequest.transfer_number"
            />
            <small>Transfer Number</small>
          </div>
        </div>
        <div
          v-if="Number(approver.stage) === 3 && Number(stockRequest.stage) >= 3 && ['Posted'].includes(approver.status)"
          class="font-weight-bolder d-flex flex-column align-items-end"
        >
          <strong
            class="mt-1"
            style="font-size: 18px"
            v-text="stockRequest.sap_doc_entry"
          />
          <small>SAP Entry</small>
        </div>
      </div>
      <div
        v-if="['Requested','Approved', 'Dispatched', 'Scheduled', 'Posted', 'Received', 'Closed', 'Canceled', 'Disapproved'].includes(approver.status)"
        class="d-flex flex-column"
      >
        <div
          class="font-weight-bolder text-dark mt-4"
          style="font-size: 16px;"
          v-text="approver.approver_name"
        />
        <small
          class="text-dark"
        >
          {{ dateTimeShortFormatter(approver.status_at) }}<span v-if="Number(approver.stage) >= 1">, {{ getTimeLapsedFromCurrentApproverToPrevious() }}</span>
        </small>
        <span
          v-if="['Approved', 'Dispatched', 'Scheduled', 'Posted', 'Received', 'Closed', 'Disapproved'].includes(approver.status)"
          class="text-dark mt-3"
        >
          Remarks: {{ approver.remarks?.length ? approver.remarks : ' - - no remarks - - ' }}
        </span>
      </div>
    </div>
  </li>
</template>

<script>
import FORMATTER from '@/mixins/formatter'
import dateDifference from 'moment'
export default {
  name: 'TimeLine',
  mixins: [
    FORMATTER
  ],

  props: {
    approver: {
      type: Object,
      default: () => ({
        stage: 0,
        approver_label: null,
        approver_action: null,
        approver_name: null,
        last_seen: null,
        status: null,
        status_at: null,
        remarks: null
      })
    },
    stockRequest: {
      type: Object,
      default: () => ({
        stage: 0,
        status: null,
        created_at: null,
        document_number: null,
        transfer_number: null,
        sap_doc_entry: null,
        purchase_number: null,
        approvals: []
      })
    }
  },

  computed: {
    variant () {
      if (['Canceled'].includes(this.stockRequest.status)) {
        return 'danger'
      }

      switch (this.approver.status) {
        case 'Pending': {
          return 'warning'
        }
        case 'Canceled': {
          return 'danger'
        }
        case 'Disapproved': {
          return 'danger'
        }
        default: {
          return 'success'
        }
      }
    },

    icon () {
      switch (this.approver.status) {
        case 'Requested': {
          return 'plus'
        }
        case 'Approved': {
          return 'check'
        }
        case 'Posted': {
          return 'p'
        }
        case 'Scheduled': {
          return 'calendar'
        }
        case 'Dispatched': {
          return 'location-arrow'
        }
        case 'Received': {
          return 'arrow-down-left'
        }
        case 'Closed': {
          return 'c'
        }
        case 'Disapproved': {
          return 'xmark'
        }
        default: {
          return 'exclamation'
        }
      }
    }
  },

  methods: {

    getTimeLapsedFromCurrentApproverToPrevious () {
      if (Number(this.approver.stage) === Number(this.stockRequest.stage) && ['Pending', 'Requested'].includes(this.approver.status)) {
        const lastApprover = this.stockRequest.approvals.find(
          approval => Number(approval.stage) < Number(this.approver.stage) && (
            Number(approval.stage) === (Number(this.approver.stage) - 1)
          )
        )
        if (lastApprover) {
          return dateDifference(lastApprover.status_at).fromNow()
        }
        return dateDifference(this.stockRequest.created_at).fromNow()
      } else {
        const lastApprover = this.stockRequest.approvals.find(
          approval => Number(approval.stage) < Number(this.approver.stage) && (
            Number(approval.stage) === (Number(this.approver.stage) - 1)
          )
        )
        if (lastApprover) {
          return dateDifference(this.approver.status_at).from(
            dateDifference(lastApprover.status_at),
            true
          )
        }
        return dateDifference(this.stockRequest.created_at).from(
          dateDifference(this.approver.status_at),
          true
        )
      }
    }
  }
}
</script>

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"pl-4"},[_c('ul',{staticClass:"iq-timeline"},[_vm._t("approvals",null,null,{
          stage: _vm.stockRequest?.stage || 0,
          status: _vm.stockRequest?.status || 'Pending',
          created_at: _vm.stockRequest?.created_at || null,
          document_number: _vm.stockRequest.references?.document_number || null,
          transfer_number: _vm.stockRequest.references?.transfer_number || ' ',
          sap_doc_entry: _vm.stockRequest.references?.sap_doc_entry || null,
          purchase_number: _vm.stockRequest.references?.purchase_number || null,
          approvals: _vm.stockRequest?.approvals || []
        })],2)])])
}
var staticRenderFns = []

export { render, staticRenderFns }
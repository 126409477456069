<template>
  <ValidationProvider
    #default="{ errors }"
    :vid="vQuantity"
    name="quantity"
    rules="required|min_value:1"
  >
    <b-form-group
      :label-for="vQuantity"
      class="mb-0"
    >
      <b-input
        :id="vQuantity"
        v-model="item.quantity"
        :state="(errors.length > 0 ? false : null)"
        :disabled="busy"
        :tabindex="(index + 1)"
        class="text-center border-0 bg-transparent"
        placeholder="0"
        type="number"
        step="1"
        min="1"
        @keydown="keydown"
        @change="change"
      />
      <div
        v-if="(errors.length > 0)"
        class="invalid-feedback text-left"
        v-text="errors[0]"
      />
    </b-form-group>
  </ValidationProvider>
</template>
<script>
export default {
  name: 'Quantity',

  props: {
    index: {
      type: Number,
      default: () => 0,
      required: true
    },
    busy: {
      type: Boolean,
      default: () => false
    },
    item: {
      type: Object,
      default: () => ({
        quantity: null
      })
    }
  },

  computed: {
    vQuantity () {
      return `details.${this.index}.quantity`
    }
  },

  methods: {

    enter () {
      this.$emit('enter', this.item, this.index)
    },

    cleared () {
      this.$emit('cleared', this.item, this.index)
    },

    change () {
      this.$emit('change', this.item, this.index)
    },

    keydown ({ which, target }) {
      if (Number(target.value) < 0 || target.value === '') {
        if (which === 8) {
          this.cleared()
        }
      }

      if (which === 13) {
        this.enter()
      }
    }
  }
}
</script>

<template>
  <b-container
    class="mt-2"
    fluid
  >
    <!-- Filters -->
    <b-row>
      <b-col
        cols="12"
        class="px-1"
      >
        <b-form-group
          label="Search"
          label-for="filterDraftSearch"
          label-class="font-weight-bolder"
        >
          <b-input
            id="filterDraftSearch"
            v-model="tableDraft.filter.search"
            type="text"
            placeholder="search here"
            debounce="1000"
            autocomplete="off"
          />
        </b-form-group>
      </b-col>
      <b-col
        cols="12"
        class="px-1"
      >
        <div
          v-if="tableDraft.totalRows > 0"
          class="d-flex order-md-1 order-2 pb-2"
          style="column-gap: 5px; row-gap: 5px;"
        >
          <b-button
            v-if="stockRequests.selected.length <= 0"
            size="sm"
            variant="outline-success"
            class="text-nowrap min-w-100px"
            @click.prevent="$event => tableSelectRows(true)"
          >
            Select All
          </b-button>
          <b-button
            v-else-if="stockRequests.selected.length > 0"
            size="sm"
            variant="outline-success"
            class="text-nowrap min-w-100px"
            @click.prevent="$event => tableSelectRows(false)"
          >
            Unselect All
          </b-button>
          <b-button
            v-if="stockRequests.selected.length > 0"
            size="sm"
            variant="outline-danger"
            class="text-nowrap"
            @click.prevent="$event => discardDrafts()"
          >
            Discard Drafts
          </b-button>
        </div>
      </b-col>
    </b-row>

    <!-- Table -->
    <b-row
      v-if="Number(tabIndex) === 1"
      class="mt-1"
    >
      <b-col
        cols="12"
        class="px-1"
      >
        <b-table
          ref="tableDraft"
          small
          hover
          striped
          bordered
          responsive
          show-empty
          :items="tableProvider"
          :busy="tableDraft.busy"
          :filter="tableDraft.filter"
          :fields="tableDraft.fields"
          :stacked="tableDraft.stacked"
          :sort-by.sync="tableDraft.sortBy"
          :sort-desc.sync="tableDraft.sortDesc"
          :sort-direction="tableDraft.sortDirection"
          :filter-included-fields="tableDraft.filterOn"
          :current-page="tableDraft.currentPage"
          :per-page="tableDraft.perPage"
        >
          <template #cell(index)="{ index }">
            {{ tableRowNumber(tableDraft, index) }}
          </template>

          <template #head(selected)>
            <b-form-checkbox
              v-model="tableDraft.selected.allSelected"
              :indeterminate="tableDraft.selected.indeterminate"
              :disabled="(tableDraft.busy || Number(tableDraft.totalRows) <= 0)"
              size="lg"
              @change="tableSelectRows"
            />
          </template>

          <template #cell(selected)="{ item }">
            <b-form-checkbox
              v-model="stockRequests.selected"
              size="lg"
              :value="item.id"
              :disabled="tableDraft.busy"
            />
          </template>

          <template #cell(action)="{ item }">
            <div class="text-md-nowrap d-flex flex-column flex-md-row justify-content-md-start justify-content-center">
              <b-overlay
                :show="String(item.id) === String(tableDraft.selected.detail)"
                rounded="sm"
                opacity="0.6"
                spinner-small
                spinner-variant="warning"
                class="mr-0 mr-md-1 mb-1 mb-md-0 p-0"
              >
                <b-button
                  size="sm"
                  variant="success"
                  :disabled="tableDraft.busy || String(item.id) === String(tableDraft.selected.detail) || stockRequests.selected.length > 0"
                  @click.prevent="show(item)"
                >
                  Edit
                </b-button>
              </b-overlay>
              <b-button
                size="sm"
                variant="outline-danger"
                :disabled="tableDraft.busy || String(item.id) === String(tableDraft.selected.detail) || stockRequests.selected.length > 0"
                @click.prevent="discardDraft(item)"
              >
                Discard
              </b-button>
            </div>
          </template>

          <template #cell()="{ value }">
            <div
              class="text-md-nowrap"
              v-text="value"
            />
          </template>

          <template #table-busy>
            <div class="text-center py-5">
              <b-icon
                icon="stopwatch"
                font-scale="5"
                animation="cylon"
              />
              <p class="h3 py-2">
                <strong>Loading . . .</strong>
              </p>
            </div>
          </template>

        </b-table>
      </b-col>

      <b-col
        cols="12"
        md="6"
        class="mt-1 mb-2 px-1"
      >
        <b-select
          v-model="tableDraft.perPage"
          :options="tableDraft.pageOptions"
          :disabled="tableDraft.busy"
          class="w-100 w-md-25"
          size="sm"
        />
      </b-col>

      <b-col
        cols="12"
        md="6"
        class="d-flex justify-content-center justify-content-md-end mt-1 px-1"
      >
        <b-pagination
          v-model="tableDraft.currentPage"
          :total-rows="tableDraft.totalRows"
          :per-page="tableDraft.perPage"
          :disabled="tableDraft.busy"
          aria-controls="table"
          prev-text="Prev"
          next-text="Next"
          first-number
          last-number
          pills
        />
      </b-col>

    </b-row>
  </b-container>
</template>
<script>
import QUERY from 'lodash'
import MISC from '@/mixins/misc'
import FORMATTER from '@/mixins/formatter'
import { SUserRequestDraft } from '@/services'

export default {

  name: 'UserRequestDraft',

  mixins: [
    MISC,
    FORMATTER
  ],

  props: {
    tabIndex: {
      type: Number,
      default: () => 1
    }
  },

  data () {
    return {
      stockRequests: {
        id: 0,
        selected: []
      },
      tableDraft: {
        selected: {
          allSelected: false,
          indeterminate: false,
          detail: null
        },
        busy: false,
        filter: {
          search: null
        },
        filterOn: [],
        stacked: this.isMobile(),
        sortBy: null,
        sortDesc: false,
        sortDirection: 'asc',
        pageOptions: [10, 25, 50, 100],
        currentPage: 1,
        totalRows: 0,
        perPage: 10,
        fields: [
          { mobile: 0, key: 'index', label: '#', class: 'text-center' },
          { mobile: 1, key: 'selected', class: 'text-center' },
          { mobile: 8, key: 'action', class: 'text-center' },
          { mobile: 2, key: 'created_at', label: 'Drafted At', class: 'min-w-175px', formatter: this.dateTimeShortFormatter, sortable: true },
          { mobile: 3, key: 'headers.origin_name', label: 'Request To', class: 'min-w-200px' },
          { mobile: 5, key: 'delivery_details.customer', label: 'Customer', class: 'min-w-200px' },
          { mobile: 6, key: 'headers.customer_direct', label: 'D2C', class: 'text-center', formatter: this.yesOrNo },
          // { mobile: 6, key: 'details.length', label: 'Products' },
          { mobile: 7, key: 'updated_at', class: 'min-w-175px', formatter: this.dateTimeShortFormatter }
        ].sort(this.scaleOnMobile)
      }
    }
  },

  watch: {
    'stockRequests.selected' (newValues) {
      if (newValues.length === 0) {
        this.tableDraft.selected.indeterminate = false
        this.tableDraft.selected.allSelected = false
      } else if (newValues.length === this.$refs.tableDraft?.localItems.length) {
        this.tableDraft.selected.indeterminate = false
        this.tableDraft.selected.allSelected = true
      } else {
        this.tableDraft.selected.indeterminate = true
        this.tableDraft.selected.allSelected = false
      }
    }
  },

  methods: {

    async tableProvider ({ currentPage, perPage, sortBy, sortDesc, filter }) {
      this.tableDraft.busy = true
      return await SUserRequestDraft.get({
        page: currentPage,
        per_page: perPage,
        sort: sortBy,
        sort_desc: sortDesc,
        filter_text: filter.search
      }).then(({ data }) => {
        this.tableDraft.totalRows = data.total_rows
        return data.items
      }).catch(() => {
        this.tableDraft.totalRows = 0
        return []
      }).finally(() => {
        this.tableDraft.busy = false
      })
    },

    tableRefresh () {
      this.$refs.tableDraft.refresh()
    },

    loaded () {
      this.tableDraft.busy = false
      this.tableDraft.selected.detail = null
    },

    tableSelectRows (selectAll) {
      this.stockRequests.selected = selectAll ? (
        this.$refs.tableDraft.localItems.map(
          draft => draft.id
        )
      ) : []
    },

    show (stockRequest) {
      this.tableDraft.selected.detail = stockRequest.id
      this.$emit('show', stockRequest)
    },

    async create (payload) {
      return new Promise((resolve, reject) => {
        SUserRequestDraft.post(payload).then(
          ({ data: { message } }) => {
            this.$bvModal.hide('modalRequest')
            this.swalSuccess(message).then(() => {
              this.tableRefresh()
            })
            resolve(message)
          }
        ).catch(
          ({ message }) => reject(message)
        )
      })
    },

    async update (payload) {
      return new Promise((resolve, reject) => {
        SUserRequestDraft.put(payload).then(
          ({ data: { message, transfer_draft } }) => {
            this.$bvModal.hide('modalRequest')
            this.swalSuccess(message).then(() => {
              if (this.tabIndex === 1) {
                const tableDraftRow = QUERY.find(this.$refs.tableDraft?.localItems, {
                  id: transfer_draft.id
                })
                if (tableDraftRow) {
                  tableDraftRow.headers = transfer_draft.headers
                  tableDraftRow.details = transfer_draft.details
                  tableDraftRow.delivery_details = transfer_draft.delivery_details
                  tableDraftRow.updated_at = transfer_draft.updated_at
                }
              }
            })
            resolve({ message, transfer_draft })
          }
        ).catch(
          ({ message }) => reject(message)
        )
      })
    },

    discardDraft (stockRequest) {
      this.swalConfirm({
        preConfirm: async () => await SUserRequestDraft.delete({
          id: 0,
          selected: [stockRequest.id]
        }).then(({ data: { message } }) => {
          this.swalSuccess(message).then(() => {
            this.tableRefresh()
          })
        }),
        html: '<h6>Discard Draft?</h6>',
        confirmButtonText: 'Discard',
        cancelButtonText: 'Cancel',
        customClass: {
          confirmButton: 'btn btn-sm btn-danger'
        }
      })
    },

    discardDrafts () {
      this.swalConfirm({
        preConfirm: async () => await SUserRequestDraft.delete(this.stockRequests).then(
          ({ data: { message } }) => {
            this.swalSuccess(message).then(() => {
              this.stockRequests.selected = []
              this.tableRefresh()
            })
          }
        ),
        html: '<h6>Discard Selected Drafts?</h6>',
        confirmButtonText: 'Discard',
        cancelButtonText: 'Cancel',
        customClass: {
          confirmButton: 'btn btn-sm btn-danger'
        }
      })
    }
  }
}
</script>
